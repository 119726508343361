<template>
  <app-card
    v-bind="$attrs"
    class="v-card--material mt-4"
  >
    <v-card-title class="align-start">
      <v-sheet
        :color="color"
        :width="fullHeader ? '100%' : undefined"
        class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
        elevation="6"
        max-width="100%"
        rounded
      >
        <v-theme-provider
          v-if="hasHeading"
          dark
        >
          <div
            v-if="icon"
            :class="iconSmall ? 'pa-2' : 'pa-3'"
          >
            <v-icon
              :large="!iconSmall"
              v-text="icon"
            />
            <span class="text-h6 white--text v-card--material__title">
              {{ title }}
            </span>
          </div>
        </v-theme-provider>
      </v-sheet>
      <v-sheet class="overflow-hidden mt-n9 transition-swing v-card--material__sheet">
        <template v-if="$slots.actions">
          <v-card-actions class="px-5 text-caption">
            <slot name="actions" />
          </v-card-actions>
        </template>
      </v-sheet>
    </v-card-title>
    <slot />
  </app-card>
</template>

<script>
  export default {
    name: 'MaterialSmallCard',

    props: {
      color: String,
      fullHeader: Boolean,
      heading: String,
      icon: String,
      iconSmall: Boolean,
      subtitle: String,
      title: String,
    },

    computed: {
      hasHeading () {
        return !!(
          this.icon ||
          this.heading ||
          this.$slots.heading
        )
      },
      hasTitle () {
        return !!(
          this.title ||
          this.subtitle ||
          this.$slots.title ||
          this.$slots.subtitle
        )
      },
    },
  }
</script>

<style lang="sass">
  .v-card.v-card--material
    > .v-card__title
      > .v-card--material__title
        flex: 1 1 auto
        word-break: break-word
</style>
