<template>
  <div class="container">
    <h3>
      {{ title }}
    </h3>
    <span class="text-h4">
      {{ body }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Toastification',

    props: {
      title: String,
      body: String,
    },
  }
</script>
