import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
//import { VueMaskDirective } from 'v-mask'
//import VueTheMask from 'vue-the-mask'
//import Chart from 'vue-frappe-chart'
import Axios from 'axios'
//import htmlToPdf from './util/htmlToPdf'
import naver from 'vue-naver-maps'
// import print from './plugins/print.js'
// import { initializeApp } from 'firebase/app'
// import { getMessaging, getToken } from 'firebase/messaging'
// import Toast from 'vue-toastification'
// import 'vue-toastification/dist/index.css'
// import Toastification from './components/notification/Toastification.vue'

Vue.use(naver, {
  //clientID: 'auwrkp2g3q',
  clientID: 'ocy5hw10hn', //real
  useGovAPI: false, //공공 클라우드 API 사용 (선택)
  subModules: '' // 서브모듈 (선택)
})


// Vue.use(Chart)
// Vue.use(Toast)
//Vue.use(htmlToPdf)
//Vue.use(VueTheMask)

//Vue.use('mask', VueMaskDirective)
Vue.config.productionTip = false

sync(store, router)

Axios.interceptors.request.use(
  (config) => {
    var accessToken = sessionStorage.getItem('x-auth-token')
    var refreshToken = localStorage.getItem('x-auth-retoken')
    // var pushToken = localStorage.getItem('push-token')
    if (accessToken && refreshToken) {
      if (validateTimeAccessToken() && validateTimeRefreshToken()) {
        // AccessToken 과 RefreshToken이 만료 시간이 유효할 경우
        config.headers['x-auth-token'] = sessionStorage.getItem('x-auth-token')
        console.log('AccessToken && RefreshToken 유효!!!')
      } else if (!validateTimeAccessToken() && validateTimeRefreshToken()) {
        // AccessToken이 만료되었고 RefreshToken이 유효할 경우
        // config.headers['x-auth-token'] = sessionStorage.getItem('x-auth-token')
        config.headers['x-auth-retoken'] = localStorage.getItem('x-auth-retoken')
        console.log('AccessToken 재발행 필요!!!')
      }
    }

    // config.headers['push-token'] = pushToken

    return config
  },
  (_error) => {
    try {
      return Promise.reject(_error)
    } catch (err) {
      console.error(err.message)
    }
  },
)

Axios.interceptors.response.use(
  (response) => {
    // console.log('Response Interceptors !!!!!!')
    // const jwt = require('jsonwebtoken')
    // const decodeAccessToken = jwt.decode(sessionStorage.getItem('x-auth-token'))

    // AccessToken을 재발행 했을 경우에 저장
    if (response.headers['x-auth-token']) {
      sessionStorage.setItem('x-auth-token', response.headers['x-auth-token'])
    }
    // console.log('HEADER : ' + response.headers['x-auth-token'])
    // RefreshToken을 재발행 했을 경우에 저장
    if (response.headers['x-auth-retoken']) {
      localStorage.setItem('x-auth-retoken', response.headers['x-auth-retoken'])
    }

    return response
  },
  (_error) => {
    console.log(_error.response.status)

    // 권한이 없을 경우 로그인 화면으로 이동
    if (_error.response.status === 401) {
      console.log('접속권한 없음 로그인 필요!!!')
      // 세션 및 로컬 스토리지 제거
      sessionStorage.clear()
      localStorage.clear()

      router.push({ name: 'auth/SignIn' })
    }
  },
)

const validateTimeAccessToken = () => {
  // sessinoStroage 에서 accessToken을 가져온다.
  const accessToken = sessionStorage.getItem('x-auth-token')
  const jwt = require('jsonwebtoken')
  // payload 추출
  const decodePayload = jwt.decode(accessToken, { payload: true })

  // UNIX Time으로 나오기 때분에 변환처리
  var exp = (new Date(decodePayload.exp * 1000)).getTime()
  var now = new Date().getTime()

  // 시간 만료
  if (now < exp) {
    console.log('AccessToken is valid')
    return true
  } else {
    console.log('AccessToken is invalid')
    return false
  }
}

const validateTimeRefreshToken = () => {
  console.log(localStorage.getItem('x-auth-retoken'))
  // localStroage 에서 refreshToken을 가져온다.
  const refreshToken = localStorage.getItem('x-auth-retoken')
  const jwt = require('jsonwebtoken')
  // payload 추출
  const decodePayload = jwt.decode(refreshToken, { payload: true })

  // UNIX Time으로 나오기 때분에 변환처리
  var exp = (new Date(decodePayload.exp * 1000)).getTime()
  var now = new Date().getTime()

  // 시간 만료
  if (now < exp) {
    console.log('RefreshToken is valid')
    return true
  } else {
    console.log('RefreshToken is invalid')
    return false
  }
}
/*
const firebaseConfig = {
  apiKey: 'AIzaSyAFqyBJD3oQQWG2mlMvX3J1Wx2rAiLeH64',
  authDomain: 'crm-glcompany.firebaseapp.com',
  databaseURL: 'https://crm-glcompany.firebaseio.com',
  projectId: 'crm-glcompany',
  storageBucket: 'crm-glcompany.appspot.com',
  messagingSenderId: '308506922909',
  appId: '1:308506922909:web:ce30d0d13c8100df466974',
  measurementId: 'G-W1DTXDF7CH',
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

/*
getToken(messaging, { vapidKey: 'BOyuPt2aBRvVhqFeAP1Ig44jxRjb9PKh4USWiOfejPJ-WMfFU6eKZP9l5S-TZHqpU4QgIKgla3Naef29-w5gX5M' }).then((currentToken) => {
  console.log('GET Token Start!!!!!!!!!!!!!!!!!!!!!!')
  if (currentToken) {
    console.log('SUCCESS !!!!! : ' + currentToken)
    // localStorage.setItem('push-token', currentToken)
  } else {
    console.log('No registration token available. Request permission to generate one.')
  }
}).catch((err) => {
  console.log('ERROR', err)
})

// 알림 수신을 위한 사용자 권한 요청
Notification.requestPermission().then((permission) => {
    console.log('permission ', permission)
    if (permission !== 'granted') {
      alert('알림을 허용해주세요!')
    }
  })

navigator.serviceWorker.addEventListener('message', function (event) {
  var title = event.data.data.title
  var body = event.data.data.content

  const content = {
    component: Toastification,
    props: {
      title: title,
      body: body,
    },
  }

  Vue.$toast(content, {
    position: 'bottom-right',
    timeout: false,
  })
})
*/

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')

// export { app, messaging }
