// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/components/dashboard/',
      meta: { level: ['1', '2', '3', '4', '5'] },
    },
    {
      title: '일정관리',
      icon: 'mdi-calendar-check',
      to: '/components/schedule/',
      meta: { level: ['1', '2', '3', '4', '5'], target: ['store'] },
    },
    {
      title: '쿠폰관리',
      icon: 'mdi-book-cog',
      items: [
        { title: '쿠폰마스터 관리', icon: 'mdi-menu-right-outline', to: '/components/CouponPolicyManage/', meta: { level: ['1', '2'], target: ['admin'] } },
        { title: '쿠폰발급 리스트', icon: 'mdi-menu-right-outline', to: '/components/CouponList/', meta: { level: ['1', '2'] } },
        { title: '쿠폰사용정보', icon: 'mdi-menu-right-outline', to: '/components/StoreCouponUseList/', meta: { level: ['1', '2', '3', '4', '5'] } },
      ],
      meta: { level: ['1', '2', '3', '4', '5'] },
    },
    {
      title: '고객관리',
      icon: 'mdi-account-multiple',
      items: [
        { title: '고객 리스트', icon: 'mdi-menu-right-outline', to: '/components/customer/', meta: { level: ['1', '2', '3', '4', '5'] } },
        { title: '거래처 리스트', icon: 'mdi-menu-right-outline', to: '/components/account/', meta: { level: ['1', '2', '3', '4', '5'], target: ['admin'] } },
      ],
      meta: { level: ['1', '2', '3', '4', '5'] },
    },
    {
      title: '매출관리',
      icon: 'mdi-clipboard-outline',
      to: '/components/sales/',
      meta: { level: ['1', '2', '3', '4', '5'] },
    },
    {
      title: '제품관리',
      icon: 'mdi-shopping-outline',
      items: [
        { title: '제품관리', icon: 'mdi-menu-right-outline', to: '/components/product/', meta: { level: ['1', '2'] } },
        { title: '주문하기', icon: 'mdi-menu-right-outline', to: '/components/order/', meta: { level: ['1', '2', '3', '4', '5'], target: ['store'] } },
        { title: '교환/반품', icon: 'mdi-menu-right-outline', to: '/components/exchangeBack/', meta: { level: ['1', '2', '3', '4', '5'], target: ['store'] } },
        { title: '주문내역', icon: 'mdi-menu-right-outline', to: '/components/orderList/', meta: { level: ['1', '2', '3', '4', '5'], target: ['store'] } },
        { title: '제품 단가관리', icon: 'mdi-menu-right-outline', to: '/components/productCostManage/', meta: { level: ['1', '2'], target: ['admin'] } },
        { title: '보증서 관리', icon: 'mdi-menu-right-outline', to: '/components/guarantee/', meta: { level: ['1', '2', '3', '4', '5'] } },
      ],
      meta: { level: ['1', '2', '3', '4', '5'] },
    },
    {
      title: '대리점 관리',
      icon: 'mdi-storefront-check',
      items: [
        { title: '주문내역', icon: 'mdi-menu-right-outline', to: '/components/storeOrderList/', meta: { level: ['1', '2', '3'] } },
        { title: '교환/반품', icon: 'mdi-menu-right-outline', to: '/components/storeExchangeBack/', meta: { level: ['1', '2', '3'] } },
        { title: '정산관리', icon: 'mdi-menu-right-outline', to: '/components/storeCalculate/', meta: { level: ['1', '2'] } },
        { title: '입출고 현황', icon: 'mdi-menu-right-outline', to: '/components/stockRelease/', meta: { level: ['1', '2', '3'] } },
        { title: '대리점관리', icon: 'mdi-menu-right-outline', to: '/components/StoreManage/', meta: { level: ['1', '2'] } },
       ],
       meta: { level: ['1', '2'], target: ['admin'] },
    },
    {
      title: '알림',
      icon: 'mdi-message-text-clock-outline',
      items: [
        { title: '발송 리스트', icon: 'mdi-menu-right-outline', to: '/components/notice/sendlist/', meta: { level: ['1', '2', '3', '4', '5'] } },
        { title: '설정 관리', icon: 'mdi-menu-right-outline', to: '/components/notice/cfg/', meta: { level: ['1', '2'] } },
        { title: '공지사항', icon: 'mdi-menu-right-outline', to: '/components/notice/board/', meta: { level: ['1', '2'] } },
      ],
      meta: { level: ['1', '2'], target: ['admin'] },
    },
    // {
    //  title: 'Google Maps',
    //  icon: 'mdi-map-marker',
    //  to: '/maps/google/',
    // },
    // {
    //  title: '알림',
    //  icon: 'mdi-bell',
    //  to: '/components/notifications/',
    // },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
